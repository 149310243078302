"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ZodiacService = void 0;
const imgCache = {};
function importAll(r) {
    r.keys().forEach((key) => (imgCache[key] = r(key).default));
}
//@ts-ignore
importAll(require.context('./assets', true, /\.(svg|jpe?g|png|webp)$/));
class ZodiacService {
    constructor($filter) {
        this.$filter = $filter;
        this.imgCache = imgCache;
        this.chinaZodiacs = [
            {
                title: this.$filter('translate')('Rat'),
                img: imgCache['./china/rat.svg'],
                stringDate: '1990-1992',
                yinyang: this.$filter('translate')('Yang'),
                element: this.$filter('translate')('Water'),
                dates: [
                    [new Date(2020, 0, 25), new Date(2021, 1, 11)]
                ]
            },
            {
                title: this.$filter('translate')('Ox'),
                img: imgCache['./china/ox.svg'],
                stringDate: '1990-1992',
                yinyang: this.$filter('translate')('Yin'),
                element: this.$filter('translate')('Earth'),
                dates: [
                    [new Date(2021, 1, 12), new Date(2022, 0, 31)]
                ]
            },
            {
                title: this.$filter('translate')('Tiger'),
                img: imgCache['./china/tiger.svg'],
                stringDate: '1990-1992',
                yinyang: this.$filter('translate')('Yang'),
                element: this.$filter('translate')('Wood'),
                dates: [
                    [new Date(2022, 1, 1), new Date(2023, 0, 21)]
                ]
            },
            {
                title: this.$filter('translate')('Rabbit'),
                img: imgCache['./china/rabbit.svg'],
                stringDate: '1990-1992',
                yinyang: this.$filter('translate')('Yin'),
                element: this.$filter('translate')('Wood'),
                dates: [
                    [new Date(2023, 0, 22), new Date(2024, 1, 9)]
                ]
            },
            {
                title: this.$filter('translate')('Dragon'),
                img: imgCache['./china/dragon.svg'],
                stringDate: '1990-1992',
                yinyang: this.$filter('translate')('Yang'),
                element: this.$filter('translate')('Earth'),
                dates: [
                    [new Date(2024, 1, 10), new Date(2025, 0, 28)]
                ]
            },
            {
                title: this.$filter('translate')('Snake'),
                img: imgCache['./china/snake.svg'],
                stringDate: '1990-1992',
                yinyang: this.$filter('translate')('Yin'),
                element: this.$filter('translate')('Fire'),
                dates: [
                    [new Date(2025, 0, 29), new Date(2026, 1, 16)]
                ]
            },
            {
                title: this.$filter('translate')('Horse'),
                img: imgCache['./china/horse.svg'],
                stringDate: '1990-1992',
                yinyang: this.$filter('translate')('Yang'),
                element: this.$filter('translate')('Fire'),
                dates: [
                    [new Date(2026, 1, 17), new Date(2027, 1, 5)]
                ]
            },
            {
                title: this.$filter('translate')('Goat'),
                img: imgCache['./china/goat.svg'],
                stringDate: '1990-1992',
                yinyang: this.$filter('translate')('Yin'),
                element: this.$filter('translate')('Earth'),
                dates: [
                    [new Date(2027, 1, 6), new Date(2028, 0, 25)]
                ]
            },
            {
                title: this.$filter('translate')('Monkey'),
                img: imgCache['./china/monkey.svg'],
                stringDate: '1990-1992',
                yinyang: this.$filter('translate')('Yang'),
                element: this.$filter('translate')('Metal'),
                dates: [
                    [new Date(2028, 0, 26), new Date(2029, 1, 12)]
                ]
            },
            {
                title: this.$filter('translate')('Rooster'),
                img: imgCache['./china/rooster.svg'],
                stringDate: '1990-1992',
                yinyang: this.$filter('translate')('Yin'),
                element: this.$filter('translate')('Metal'),
                dates: [
                    [new Date(2029, 1, 13), new Date(2030, 1, 2)]
                ]
            },
            {
                title: this.$filter('translate')('Dog'),
                img: imgCache['./china/dog.svg'],
                stringDate: '1990-1992',
                yinyang: this.$filter('translate')('Yang'),
                element: this.$filter('translate')('Earth'),
                dates: [
                    [new Date(2030, 1, 3), new Date(2031, 0, 22)]
                ]
            },
            {
                title: this.$filter('translate')('Pig'),
                img: imgCache['./china/pig.svg'],
                stringDate: '1990-1992',
                yinyang: this.$filter('translate')('Yin'),
                element: this.$filter('translate')('Water'),
                dates: [
                    [new Date(2031, 0, 23), new Date(2032, 1, 10)]
                ]
            },
        ];
        this.zodiacs = [
            {
                title: this.$filter('translate')('Aries'),
                symbol: this.$filter('translate')('Ram'),
                element: this.$filter('translate')('Fire'),
                modality: this.$filter('translate')('Cardinal'),
                planet: this.$filter('translate')('Mars'),
                metal: this.$filter('translate')('Iron'),
                day: this.$filter('translate')('Tuesday'),
                number: '9, 6',
                color: this.$filter('translate')('Red'),
                gemstone: this.$filter('translate')('Diamond'),
                flower: this.$filter('translate')('Honeysuckle'),
                tarot: this.$filter('translate')('The Emperor'),
                house: 'I',
                opposite: this.$filter('translate')('Libra'),
                img: imgCache['./aries.svg'],
                from: {
                    month: 3 - 1,
                    date: 21
                },
                to: {
                    month: 4 - 1,
                    date: 19
                },
                current: false,
            },
            {
                title: this.$filter('translate')('Taurus'),
                symbol: this.$filter('translate')('Bull'),
                element: this.$filter('translate')('Earth'),
                modality: this.$filter('translate')('Fixed'),
                planet: this.$filter('translate')('Venus'),
                metal: this.$filter('translate')('Copper'),
                day: this.$filter('translate')('Friday'),
                number: '5, 6',
                color: this.$filter('translate')('Green'),
                gemstone: this.$filter('translate')('Emerald'),
                flower: this.$filter('translate')('Poppy'),
                tarot: this.$filter('translate')('The Hierophant'),
                house: 'II',
                opposite: this.$filter('translate')('Scorpio'),
                img: imgCache['./taurus.svg'],
                from: {
                    month: 4 - 1,
                    date: 20
                },
                to: {
                    month: 5 - 1,
                    date: 20
                },
                current: false,
            },
            {
                title: this.$filter('translate')('Gemini'),
                symbol: this.$filter('translate')('Twins'),
                element: this.$filter('translate')('Air'),
                modality: this.$filter('translate')('Mutable'),
                planet: this.$filter('translate')('Mercury'),
                metal: this.$filter('translate')('Silver'),
                day: this.$filter('translate')('Wednesday'),
                number: '5',
                color: this.$filter('translate')('Yellow'),
                gemstone: this.$filter('translate')('Pearl'),
                flower: this.$filter('translate')('Lavender'),
                tarot: this.$filter('translate')('The Lovers'),
                house: 'III',
                opposite: this.$filter('translate')('Sagittarius'),
                img: imgCache['./gemini.svg'],
                from: {
                    month: 5 - 1,
                    date: 21
                },
                to: {
                    month: 6 - 1,
                    date: 20
                },
                current: false,
            },
            {
                title: this.$filter('translate')('Cancer'),
                symbol: this.$filter('translate')('Crab'),
                element: this.$filter('translate')('Water'),
                modality: this.$filter('translate')('Cardinal'),
                planet: this.$filter('translate')('Moon'),
                metal: this.$filter('translate')('Silver'),
                day: this.$filter('translate')('Monday'),
                number: '2',
                color: this.$filter('translate')('White'),
                gemstone: this.$filter('translate')('Ruby'),
                flower: this.$filter('translate')('Acanthus'),
                tarot: this.$filter('translate')('The Chariot'),
                house: 'IV',
                opposite: this.$filter('translate')('Capricorn'),
                img: imgCache['./cancer.svg'],
                from: {
                    month: 6 - 1,
                    date: 21
                },
                to: {
                    month: 7 - 1,
                    date: 22
                },
                current: false,
            },
            {
                title: this.$filter('translate')('Leo'),
                symbol: this.$filter('translate')('Lion'),
                element: this.$filter('translate')('Fire'),
                modality: this.$filter('translate')('Fixed'),
                planet: this.$filter('translate')('Sun'),
                metal: this.$filter('translate')('Gold'),
                day: this.$filter('translate')('Sunday'),
                number: '1',
                color: this.$filter('translate')('Gold2'),
                gemstone: this.$filter('translate')('Peridot'),
                flower: this.$filter('translate')('Sunflower'),
                tarot: this.$filter('translate')('Strength'),
                house: 'V',
                opposite: this.$filter('translate')('Aquarius'),
                img: imgCache['./leo.svg'],
                from: {
                    month: 7 - 1,
                    date: 23
                },
                to: {
                    month: 8 - 1,
                    date: 22
                },
                current: false,
            },
            {
                title: this.$filter('translate')('Virgo'),
                symbol: this.$filter('translate')('Virgin'),
                element: this.$filter('translate')('Earth'),
                modality: this.$filter('translate')('Mutable'),
                planet: this.$filter('translate')('Mercury'),
                metal: this.$filter('translate')('Platinum'),
                day: this.$filter('translate')('Wednesday'),
                number: '3',
                color: this.$filter('translate')('Navy blue'),
                gemstone: this.$filter('translate')('Sapphire'),
                flower: this.$filter('translate')('Morning glory'),
                tarot: this.$filter('translate')('The Hermit'),
                house: 'VI',
                opposite: this.$filter('translate')('Pisces'),
                img: imgCache['./virgo.svg'],
                from: {
                    month: 8 - 1,
                    date: 23
                },
                to: {
                    month: 9 - 1,
                    date: 22
                },
                current: false,
            },
            {
                title: this.$filter('translate')('Libra'),
                symbol: this.$filter('translate')('Scales'),
                element: this.$filter('translate')('Air'),
                modality: this.$filter('translate')('Cardinal'),
                planet: this.$filter('translate')('Venus'),
                metal: this.$filter('translate')('Bronze'),
                day: this.$filter('translate')('Friday'),
                number: '7',
                color: this.$filter('translate')('Blue'),
                gemstone: this.$filter('translate')('Opal'),
                flower: this.$filter('translate')('Rose'),
                tarot: this.$filter('translate')('Justice'),
                house: 'VII',
                opposite: this.$filter('translate')('Aries'),
                img: imgCache['./libra.svg'],
                from: {
                    month: 9 - 1,
                    date: 23
                },
                to: {
                    month: 10 - 1,
                    date: 22
                },
                current: false,
            },
            {
                title: this.$filter('translate')('Scorpio'),
                symbol: this.$filter('translate')('Scorpion'),
                element: this.$filter('translate')('Water'),
                modality: this.$filter('translate')('Fixed'),
                planet: this.$filter('translate')('Mars, Pluto'),
                metal: this.$filter('translate')('Steel'),
                day: this.$filter('translate')('Tuesday'),
                number: '8',
                color: this.$filter('translate')('Black'),
                gemstone: this.$filter('translate')('Topaz'),
                flower: this.$filter('translate')('Chrysanthemum'),
                tarot: this.$filter('translate')('Death'),
                house: 'VIII',
                opposite: this.$filter('translate')('Taurus'),
                img: imgCache['./scorpio.svg'],
                from: {
                    month: 10 - 1,
                    date: 23
                },
                to: {
                    month: 11 - 1,
                    date: 21
                },
                current: false,
            },
            {
                title: this.$filter('translate')('Sagittarius'),
                symbol: this.$filter('translate')('Archer'),
                element: this.$filter('translate')('Fire'),
                modality: this.$filter('translate')('Mutable'),
                planet: this.$filter('translate')('Jupiter'),
                metal: this.$filter('translate')('Tin'),
                day: this.$filter('translate')('Thursday'),
                number: '3',
                color: this.$filter('translate')('Purple'),
                gemstone: this.$filter('translate')('Tanzanite'),
                flower: this.$filter('translate')('Narcissus'),
                tarot: this.$filter('translate')('Temperance'),
                house: 'IX',
                opposite: this.$filter('translate')('Gemini'),
                img: imgCache['./sagittarius.svg'],
                from: {
                    month: 11 - 1,
                    date: 22
                },
                to: {
                    month: 12 - 1,
                    date: 21
                },
                current: false,
            },
            {
                title: this.$filter('translate')('Capricorn'),
                symbol: this.$filter('translate')('Goat'),
                element: this.$filter('translate')('Earth'),
                modality: this.$filter('translate')('Cardinal'),
                planet: this.$filter('translate')('Saturn'),
                metal: this.$filter('translate')('Silver'),
                day: this.$filter('translate')('Saturday'),
                number: '4',
                color: this.$filter('translate')('Brown'),
                gemstone: this.$filter('translate')('Garnet'),
                flower: this.$filter('translate')('Carnation'),
                tarot: this.$filter('translate')('The Devil'),
                house: 'X',
                opposite: this.$filter('translate')('Cancer'),
                img: imgCache['./capricorn.svg'],
                from: {
                    month: 12 - 1,
                    date: 22
                },
                to: {
                    month: 1 - 1,
                    date: 19
                },
                current: false,
            },
            {
                title: this.$filter('translate')('Aquarius'),
                symbol: this.$filter('translate')('Water Bearer'),
                element: this.$filter('translate')('Air'),
                modality: this.$filter('translate')('Fixed'),
                planet: this.$filter('translate')('Saturn, Uranus'),
                metal: this.$filter('translate')('Platinum'),
                day: this.$filter('translate')('Saturday'),
                number: '11',
                color: this.$filter('translate')('Electric blue'),
                gemstone: this.$filter('translate')('Amethyst'),
                flower: this.$filter('translate')('Orchid'),
                tarot: this.$filter('translate')('The Star'),
                house: 'XI',
                opposite: this.$filter('translate')('Leo'),
                img: imgCache['./aquarius.svg'],
                from: {
                    month: 1 - 1,
                    date: 20
                },
                to: {
                    month: 2 - 1,
                    date: 18
                },
                current: false,
            },
            {
                title: this.$filter('translate')('Pisces'),
                symbol: this.$filter('translate')('Fishes'),
                element: this.$filter('translate')('Water'),
                modality: this.$filter('translate')('Mutable'),
                planet: this.$filter('translate')('Jupiter, Neptune'),
                metal: this.$filter('translate')('Aluminium'),
                day: this.$filter('translate')('Thursday'),
                number: '7',
                color: this.$filter('translate')('Sea green'),
                gemstone: this.$filter('translate')('Aquamarine'),
                flower: this.$filter('translate')('Water lily'),
                tarot: this.$filter('translate')('The Moon'),
                house: 'XII',
                opposite: this.$filter('translate')('Virgo'),
                img: imgCache['./pisces.svg'],
                from: {
                    month: 2 - 1,
                    date: 19
                },
                to: {
                    month: 3 - 1,
                    date: 20
                },
                current: false,
            },
        ];
    }
    getForDate(date) {
        const year = date.getFullYear();
        let item;
        let index = 0;
        for (item of this.zodiacs) {
            let toYear = year;
            if (item.from.month > item.to.month) {
                toYear += 1;
            }
            const from = new Date(year, item.from.month, item.from.date);
            const to = new Date(toYear, item.to.month, item.to.date);
            if ((date >= from) && (date <= to)) {
                item.current = true;
                item.index = index;
                break;
            }
            index += 1;
        }
        return item;
    }
}
exports.ZodiacService = ZodiacService;
ZodiacService.$inject = ['$filter'];
